import { useCollection } from "../../../firebase";
import { useMongoAggregate } from "../../../utils/mongoAggregate/useMongoAggregate";
import React, { useEffect, useState } from "react";
import _ from "lodash";

const OrdersDataContext = React.createContext();

const projectToAggregate = (project, prefix = "$data.") =>
  _.chain(project)
    .uniq()
    .reduce((p, a) => ({ ...p, [a]: `${prefix}${a}` }), {
      _id: 0,
    })
    .value();

const fields = [
  "baskets",
  "shippingBoxes",
  "pallets",
  "objectID",
  "__v",
  "internalId_daily",
  "internalId",
  "data.address.formatted_address",
  "data.store.storeName",
  "data.user.name",
  "data.user.razonsocial",
];

export function OrdersProvider({
  children,
  index = "orders",
  ordersIDs = [],
  roadmapID = "",
}) {
  const aggregate = [
    {
      $match: {
        "data.objectID": { $in: ordersIDs },
      },
    },
    {
      $project: projectToAggregate(fields),
    },
  ];

  const [orders, setOrders] = useState([]);

  const [fdocs] = useCollection({
    collection: _.size(ordersIDs) && index,
    where: [
      ["roadmap", "==", roadmapID],
      ["algoliaUpdated", "==", false],
    ],
  });

  const [mongoData] = useMongoAggregate({
    index,
    aggregate,
    enabled: !!_.size(ordersIDs),
  });
  console.log('fdocs', fdocs)

  useEffect(() => {
    const merge = _.uniqBy(
      _.orderBy(
        [
          ...(mongoData || []),
          ...(fdocs?.filter((d) => ordersIDs.includes(d.objectID)) || []).map(
            (d) => ({
              ...d,
              local: false,
              __v:
                (orders?.find(({ objectID }) => objectID === d.objectID)?.__v ||
                  d.__v) + 0.01,
            }),
          ),
          ...(orders || []).map((d) => ({ ...d, local: true })),
        ],
        ["__v", "algoliaUpdated", "local"],
        ["desc", "asc", "asc"],
      ),
      "objectID",
    );

    !_.isEqual(_.clone(orders), _.clone(merge)) && setOrders(merge);
  }, [fdocs, mongoData]);

  return (
    <OrdersDataContext.Provider value={orders}>
      {children}
    </OrdersDataContext.Provider>
  ); //[orders, loading];
}

export function useOrdersDataContext() {
  const context = React.useContext(OrdersDataContext);
  if (context === undefined) {
    throw new Error("OrdersStateContext must be used within a OrdersProvider");
  }
  return context;
}
