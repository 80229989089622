import { db, FieldValue, updateDoc, useDocumentData } from "../../../firebase";
import { OrdersProvider, useOrdersDataContext } from "./useGetOrders";
import _ from "lodash";

import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef } from "react";

import moment from "moment";
import { PageHeader } from "../PageHeader";
import { Sf } from "../../../components/Widget/SnowFlakes";
import {
  faBox,
  faPallet,
  faShoppingBasket,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUserData } from "../../../context/UserContext";
import pallet_box from "./pallet-box.png";
import truck_box from "./box_truck.png";
import RemoveBasket from "./components/RemoveBasket";
import { RoadmapTasksRender } from "./components/task-render";
import { Delete } from "@mui/icons-material";
import { useModal } from "../../app/utils/useModal";
import { useLocationQuery } from "../../../utils/useLocationQuery";
import { GenericModal } from "../../app/utils/GenericModal";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useCommonInfo } from "../useCommonInfo";
import Html5QrcodePlugin from "../../../utils/Html5QrcodePlugin";
import { Html5QrcodeScanType } from "html5-qrcode";
import escaner from "../../../images/escaner.mp3";
import barcodeScanner from "../../../images/barcode-scan.png";

import { CameraAlt } from "@material-ui/icons";
import useScannedCode from "../../../components/Utils/useScannedCode";

export default function RoadmapBaskets() {
  const { roadmapID } = useParams();

  const [roadmapData] = useDocumentData("roadmaps", roadmapID);
  const ordersIDs = _.values(roadmapData?.items)
    .map((v) => v?.order)
    .filter((v) => v);

  return (
    <OrdersProvider ordersIDs={ordersIDs} roadmapID={roadmapID}>
      <RoadmapBasketsEl roadmapID={roadmapID} roadmapData={roadmapData} />
    </OrdersProvider>
  );
}

const scanQr = ({ orders, scannedQr }) => {

  const separator = scannedQr?.includes("/") ? "/" : "-";

  const encodedId = scannedQr?.split(separator)?.at(-2);
  const boxID = scannedQr?.split(separator)?.at(-1);

  let str = String(parseInt(encodedId, 36));
  const internalId = `20${str.slice(0, 2)}-${str.slice(2, 4)}-${str.slice(
    4,
    6,
  )}-${str.slice(6)}`;

  const order = orders.find(
    (order) => order.internalId === internalId,
  );

  return { orderID: order?.objectID , boxID };
}

export function RoadmapBasketsEl({ roadmapData }) {
  const orders = useOrdersDataContext();
  const [scan, setScan] = useState(false);
  const audioRef = useRef(null);

  const ordersFiltered = _.values(roadmapData?.items)
    .reverse()
    ?.map((ri) => {
      if (Object.hasOwn(ri, "task")) return ri;
      return orders?.find((o) => o.objectID === ri.order);
    })
    .filter((d) => d);

  const itemHasTaskProp = (o) => Object.hasOwn(o, "task");
  const { parsedHash } = useLocationQuery();

  const setIn = useSetIn({
    type: "shippingBoxes",
  });

  const { scannedCode: scannedCodeByScanner, timestamp: scannedTimestamp } = useScannedCode()

  const onNewScanResult = (link) => {

    const { orderID, boxID } = scanQr({ scannedQr: link, orders });

    orderID && setIn({ id: boxID, scanned: true, orderID });

    audioRef.current.play();
  };

  useEffect(() => {
    if(scannedCodeByScanner) onNewScanResult(scannedCodeByScanner);
  }, [scannedTimestamp])


  const handleAudioEnded = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
  };
  return useMemo(
    () => (
      <>
        <PageHeader
          title={
            roadmapData
              ? [
                roadmapData?.data?.driver,
                moment(roadmapData?.date).format("DD/MM"),
              ].join(" ")
              : "Hoja de Ruta"
          }
        />
        <audio onEnded={handleAudioEnded} ref={audioRef} src={escaner}></audio>
        <Grid
          container
          sx={{
            backgroundColor: "white",
            width: "400px",
            maxWidth: "100%",
            margin: "auto",
          }}
        >
          <Button startIcon={<CameraAlt />} color={scan ? 'secondary' : 'primary'} onClick={() => setScan(prev => !prev)} variant="contained" >{scan ? 'cerrar' : "Abrir"} escaner</Button>
          {scan && <Grid
            sx={{
              position: "sticky",
              top: 0,
              background: "white",
              width: "100%",
              zIndex: 1300,
              justifyItems: "center",
              height: "250px",
              padding: 3,
            }}
            xs={12}
            item
          >
            <Html5QrcodePlugin
              fps={10}
              aspectRatio={1.0}
              disableFlip={false}
              styles={{ width: "200px", height: '80px' }}
              supportedScanTypes={[Html5QrcodeScanType.SCAN_TYPE_CAMERA]}
              qrCodeSuccessCallback={onNewScanResult}
              setStop={() => setScan(false)}
            />
          </Grid>}
          <Grid xs={12} item>
            <RoadmapHeader roadmapData={roadmapData} />
            <Divider />
          </Grid>
          {ordersFiltered?.map((o) => (
            <Stack key={itemHasTaskProp(o) ? o.task : o.objectID}>
              {!itemHasTaskProp(o) && (
                <OrderCard
                  key={o.objectID}
                  orderData={o}
                  roadmapID={roadmapData?.objectID}
                />
              )}
              {itemHasTaskProp(o) && (
                <RoadmapTasksRender key={o.task} objectID={o.task} />
              )}
            </Stack>
          ))}
        </Grid>
      </>
    ),
    [roadmapData, orders, parsedHash?.deleteTasks, scan],
  );
}

const places = {
  pallets: "inPallet",
  truck: "inTruck",
};

function useSetIn({ type = "baskets", orderId = "" }) {
  const { place } = useParams();
  const { uid } = useUserData();

  return useCallback(
    ({ id = "", set = 1, scanned = false, orderID = null, newPlace = null }) => {
      (orderId || orderID) &&
        updateDoc("orders", orderId || orderID, {
          [`${type}.${id}.${places[newPlace || place]}`]: set
            ? {
              t: (new Date() / 1000) | 0,
              by: uid,
              scanned
            }
            : FieldValue.delete(),
        });
    },
    [place, uid, type, orderId],
  );
}

function RoadmapHeader({ roadmapData }) {
  const { place } = useParams();
  const history = useHistory();

  return (
    <Grid container spacing={1}>
      <Grid item sx={{ fontSize: "1.4rem", margin: "auto" }}>
        {_.startCase(moment(roadmapData?.date).format("dddd"))}{" "}
        {moment(roadmapData?.date).format("DD/MM/YYYY")}{" "}
        {roadmapData?.departure_time}
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"h1"} sx={{ fontSize: "1.5rem", paddingLeft: 1 }}>
          {roadmapData?.data?.driver}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"h2"} sx={{ fontSize: "1rem", paddingLeft: 1 }}>
          {roadmapData?.data?.vehicle?.manufacturer}{" "}
          {roadmapData?.data?.vehicle?.model}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
        <Typography variant={"h2"} sx={{ fontSize: "1rem", paddingLeft: 1 }}>
          Poniendo los items en:
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ padding: 1 }}>
        <Button
          fullWidth
          variant={place === "pallets" ? "contained" : "outlined"}
          startIcon={<FontAwesomeIcon icon={faPallet} />}
          onClick={() => place !== "pallets" && history.replace("./pallets")}
        >
          En Pallet
        </Button>
      </Grid>
      <Grid item xs={6} sx={{ padding: 1 }}>
        <Button
          fullWidth
          variant={place === "truck" ? "contained" : "outlined"}
          startIcon={<FontAwesomeIcon icon={faTruck} />}
          onClick={() => place !== "truck" && history.replace("./truck")}
        >
          En Camion
        </Button>
      </Grid>
    </Grid>
  );
}

function OrderCard({ orderData, roadmapID }) {
  const { parsedHash } = useLocationQuery();
  const [openModalDelete, setOpenModalDelete] = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const commonData = useCommonInfo();

  const deleteOrder = async () => {
    setIsLoading(true);
    await db.runTransaction(async (t) => {
      const roadmapData = (await t.get(db.doc(`roadmaps/${roadmapID}`))).data();
      const roadmap = { ...roadmapData, id: roadmapID };

      let roadmapItems = Object.values(roadmap.items || {});

      await t.update(db.collection("orders").doc(orderData.objectID), {
        shipmentState: "pending",
        roadmap: null,
        ...commonData,
        algoliaUpdated: false,
      });

      roadmapItems = roadmapItems.filter((v) => v.order !== orderData.objectID);

      await t.update(db.collection("roadmaps").doc(roadmap.id), {
        items: roadmapItems,
        ...commonData,
        algoliaUpdated: false,
      });
    });
    setIsLoading(false);
    setOpenModalDelete(false);
  };

  return useMemo(
    () => (
      <Grid
        container
        item
        xs={12}
        sx={{ minWidth: "100%", paddingLeft: 1, marginBottom: 4 }}
      >
        <Grid item xs={12} style={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant={"h5"}
              sx={{ fontSize: "1rem", fontWeight: 500 }}
            >
              {orderData?.internalId_daily}
              {" - "}
              {orderData?.data?.store?.storeName ||
                orderData?.data?.user?.razonsocial ||
                orderData?.data?.user?.name}
            </Typography>
            {parsedHash?.deleteTasks === "true" && (
              <IconButton
                color="error"
                onClick={() => setOpenModalDelete(orderData?.objectID)}
              >
                <Delete />
              </IconButton>
            )}
          </Box>
          <Typography
            variant={"subtitle1"}
            sx={{
              fontSize: "0.7rem",
              padding: 0,
            }}
          >
            {orderData?.data?.address?.formatted_address}
          </Typography>
        </Grid>

        <OrderShippingBoxes orderData={orderData} />
        <OrderBaskets orderData={orderData} />
        <OrderPallets orderData={orderData} />
        <GenericModal
          open={openModalDelete}
          handleClose={() => setOpenModalDelete(false)}
        >
          <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
            ¿Estás seguro de eliminar esta orden de la hoja de ruta?
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: 2,
              gap: 4,
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpenModalDelete(false)}
            >
              Cancelar
            </Button>
            <LoadingButton
              loading={isLoading}
              variant="outlined"
              color="secondary"
              onClick={deleteOrder}
            >
              Eliminar
            </LoadingButton>
          </Box>
        </GenericModal>
      </Grid>
    ),
    [
      orderData?.__v,
      orderData?.algoliaUpdated,
      parsedHash?.deleteTasks,
      openModalDelete,
      isLoading,
    ],
  );
}

function OrderShippingBoxes({ orderData }) {
  const setIn = useSetIn({
    type: "shippingBoxes",
    orderId: orderData.objectID,
  });
  const { roles } = useUserData();

  const { place } = useParams();

  return (
    <>
      {_.chain(orderData?.shippingBoxes)
        .entries()
        .filter(([, v]) => v.q && !v.p)
        .sortBy([
          ([, v]) => -(v.c || 0),
          ([d]) => Number(d.replace(/[^0-9]/g, "")),
        ])
        .map(([boxId, v]) => (
          <Grid
            key={boxId}
            item
            xs={12}
            onClick={() => roles?.includes('admin') && setIn({ id: boxId, set: !v[places[place]] })}
            sx={{
              width: "100%",
              borderBottom: "thin rgba(0, 0, 0, 0.12) solid",
              lineHeight: "50px",
              fontSize: "20px",
              position: "relative",
            }}
          >
            <FontAwesomeIcon icon={faBox} /> {orderData?.internalId_daily}
            {" | "}
            {boxId} <Sf q={v.c} />
            {v.inPallet && (
              <Box
                component="img"
                sx={{
                  height: 50,
                  position: "absolute",
                  right: "150px",
                }}
                alt="En Pallet"
                src={pallet_box}
              />
            )}
            {v?.inTruck?.scanned && (
              <Box
                component="img"
                sx={{
                  alignSelf: "end",
                  height: 30,
                  position: "absolute",
                  right: "80px",
                  top: "5px",
                }}
                alt="scanner"
                src={barcodeScanner}
              />
            )}
            {v.inTruck && (
              <Box
                component="img"
                sx={{
                  alignSelf: "end",
                  height: 50,
                  position: "absolute",
                  right: "10px",
                }}
                alt="En Camion"
                src={truck_box}
              />
            )}
          </Grid>
        ))
        .value()}
    </>
  );
}

function OrderBaskets({ orderData }) {
  const baskets = _.chain(orderData?.baskets)
    .entries()
    .filter(
      ([, v]) => (v.q || v.state === "divided") && v.state !== "shippingBox",
    )
    .sortBy([
      ([, v]) => -(v.c || 0),
      ([d]) => (d.match(/^\d/) ? Number(d.replace(/[^0-9]/g, "")) : d),
    ])
    .value();
  return (
    <>
      {baskets?.map(([basketId, v]) => (
        <ListItem
          key={basketId}
          type={"baskets"}
          {...{ itemId: basketId, itemData: v, orderData, baskets }}
        />
      ))}
    </>
  );
}

function OrderPallets({ orderData }) {
  const pallets = _.chain(orderData?.shippingBoxes)
    .values()
    .filter((v) => v?.p?.id && v?.q)
    .reduce(
      (p, v) => ({
        ...p,
        [v?.p?.id]: {
          q: (p[v?.p?.id]?.q || 0) + 1,
          c: _.max([p[v?.p?.id]?.c || 0, v?.c || 0]),
          inTruck: p[v?.p?.id]?.inTruck || v?.inTruck,
          inPallet: p[v?.p?.id]?.inPallet || v?.inPallet,
        },
      }),
      {},
    )
    .entries()
    .value();

  const { uid } = useUserData();
  const { place } = useParams();

  const handleClick = ({ itemId, set = true }) => {
    updateDoc(
      "orders",
      orderData.objectID,
      _.chain(orderData?.shippingBoxes)
        .pickBy((v) => v.p?.id === itemId)
        .keys()
        .reduce(
          (p, v) => ({
            ...p,
            [`shippingBoxes.${v}.${places[place]}`]: set
              ? {
                t: (new Date() / 1000) | 0,
                by: uid,
              }
              : FieldValue.delete(),
          }),
          {},
        )
        .value(),
    );
  };

  return (
    <>
      {pallets?.map(([itemId, itemData]) => (
        <ListItem
          key={itemId}
          type={"pallets"}
          {...{ itemId, itemData, orderData, pallets, handleClick }}
        />
      ))}
    </>
  );
}

const ListItem = ({
  type = "baskets",
  itemId,
  itemData = {},
  orderData = {},
  baskets = [],
  handleClick,
}) => {
  const { uid } = useUserData();
  const { place } = useParams();

  return (
    <Grid
      key={itemId}
      item
      xs={12}
      onClick={() =>
        itemData.state !== "divided" &&
        (_.isFunction(handleClick)
          ? handleClick({ itemId, set: !itemData[places[place]] })
          : updateDoc("orders", orderData.objectID, {
            [`${type}.${itemId}.${places[place]}`]: !itemData[places[place]]
              ? {
                t: (new Date() / 1000) | 0,
                by: uid,
              }
              : FieldValue.delete(),
          }))
      }
      sx={{
        borderBottom: "thin rgba(0, 0, 0, 0.12) solid",
        lineHeight: "50px",
        fontSize: "20px",
        cursor: "pointer",
        position: "relative",
        textDecoration: itemData.state === "divided" ? "line-through" : "none",
      }}
    >
      {type === "baskets" ? (
        <FontAwesomeIcon icon={faShoppingBasket} />
      ) : (
        type === "pallets" && <FontAwesomeIcon icon={faPallet} />
      )}{" "}
      {itemId} ({itemData.q}) <Sf q={itemData.c} />
      {itemData.inPallet && (
        <Box
          component="img"
          sx={{
            height: 50,
            position: "absolute",
            right: "150px",
          }}
          alt="En Pallet"
          src={pallet_box}
        />
      )}
      {itemData.inTruck && (
        <Box
          component="img"
          sx={{
            height: 50,
            position: "absolute",
            right: "80px",
          }}
          alt="En Camion"
          src={truck_box}
        />
      )}
      {!itemData.inTruck && baskets.filter(([, v]) => v.q)?.length > 1 && (
        <RemoveBasket
          orderId={orderData.objectID}
          basketId={itemId}
          orderData={orderData}
        />
      )}
    </Grid>
  );
};
