// file = Html5QrcodePlugin.jsx
import { Html5Qrcode } from "html5-qrcode";
import { useEffect } from "react";
const qrcodeRegionId = "html5qr-code-full-region";

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props) => {
  let config = {};
  if (props.fps) {
    config.fps = props.fps;
  }
  if (props.qrbox) {
    config.qrbox = props.qrbox;
  }
  if (props.aspectRatio) {
    config.aspectRatio = props.aspectRatio;
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip;
  }
  if (props.supportedScanTypes) {
    config.supportedScanTypes = props.supportedScanTypes;
  }
  return config;
};
const Html5QrcodePlugin = (props) => {
  useEffect(() => {
    const config = createConfig(props);
    if (!props.qrCodeSuccessCallback) {
      throw "qrCodeSuccessCallback is required callback.";
    }
    const html5QrCode = new Html5Qrcode(qrcodeRegionId);
    html5QrCode.start(
      { facingMode: "environment" },
      config,
      props.qrCodeSuccessCallback
    );
    // cleanup function when component will unmount
    return () => {
      html5QrCode.stop();
    };
  }, []);
  const customStyles = props?.styles || {}

  return <div style={customStyles} id={qrcodeRegionId} />;
};

export default Html5QrcodePlugin;
