import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
  FormLabel,
  Box,
} from "@mui/material";
import _ from "lodash";
import { MuiDateRangePicker } from "../../../../../utils/MuiCalendar";
import { useState } from "react";
import moment from "moment";

export default function Filters({
  columns,
  defaultFilters,
  responseFacets,
  match,
  disabledDefaultFilters,
  dispatch,
  facets,
  dispatchFacets,
  requestFacets,
}) {
  const dateFilters = Object.entries(
    _.pickBy(requestFacets, (v) => v?.type === "date") || {},
  );

  return (
    <>
      {/* date filters */}

      {dateFilters.map(([key, filter]) => (
        <DateFilter disabledDefaultFilters={disabledDefaultFilters} defaultFilters={defaultFilters} dispatch={dispatch} key={key} filter={filter} />
      ))}
      {_.entries(facets)
        ?.filter(
          ([key, value]) =>
            (value?.length ||
              match[key]?.length ||
              (!_.isArray(match[key]) && match[key])) &&
            !key.includes("_multiple"),
        )
        ?.map(([key, value]) => (
          <Filter
            key={key}
            k={key}
            responseFacets={responseFacets}
            value={value}
            columns={columns}
            match={match}
            dispatch={dispatch}
            dispatchFacets={dispatchFacets}
            facets={facets}
            requestFacets={requestFacets}
          />
        ))}
    </>
  );
}

function Filter({
  k: key,
  value,
  match,
  columns,
  dispatch,
  responseFacets,
  requestFacets,
  dispatchFacets,
  facets,
}) {
  const field = requestFacets[key]?.field;
  const facetLimit = dispatchFacets[key]?.limit;
  const othersFilters = (_.isArray(match[key]) ? match[key] : [match[key]])
  ?.filter((d) => d && !value.map((v) => v._id).includes(d))
  ?.map((d) => ({ _id: d, count: 0, selected: 1 }));

  return (
    <FormGroup>
      <FormLabel component="legend">
        {columns.find((c) => c.field === field)?.headerName || field}
      </FormLabel>

      {/* all filters */}
      {_.chain([...othersFilters, ...value.filter(e => e)])
        .map((v) => ({
          ...v,
          keyName: Object.keys(v?.[key]?.[0] || {})?.find((a) => a !== "_id"),
          multiple:
            facets[`${key}_multiple`]?.find((d) => d._id === v._id)?.count || 0,
          checked: 
          requestFacets[key]?.type === "checkbox" || requestFacets[key]?.type === "button" ? 
            (value.length <= 1 
              ? true :
               false) : 
            _.isObject(match[key])
            ? match[key].includes(v._id)
            : match[key] === v._id,
        }))
        .sortBy(["checked", "multiple", "count"])
        .reverse()
        .value()
        .map((v) => (
          <>
          <FormControlLabel
            key={v._id}
            onChange={() => {
              const countData = responseFacets[key].find(facet => facet._id === v._id)?.count

              if(_.isBoolean(v._id)){
                if(v.checked){
                  return dispatch({ type: 'delete-default-filter-by-key', keyToRemove: dispatchFacets[key].field })
                }
                return dispatch({
                  type: "set-default-filters",
                  keepPrevius: true,
                  nextFilters: {
                    [dispatchFacets[key].field]: v._id
                  }
                })
              }

              if(!v.checked){
                dispatch({
                  type: 'set-skip', nextSkip: countData
                })
              }
              dispatch({
                type: 'add-match-filter',
                currentMatch: match[key],
                wasMatchAlreadyAdded: v.checked,
                matchKey: key,
                id: v._id,
              })
            }
            }
            control={<Checkbox checked={v.checked} />}
            label={
              <>
              {console.log(v._id)}
                {_.isBoolean(v._id)
                  ? v._id
                    ? "Si"
                    : "No"
                  : v?.[key]?.[0]?.[v?.keyName] || (_.isObject(v._id) ? '' : v._id)}{" "}
                <Chip label={v.count} />{" "}
                {!v.checked && !!v?.multiple && (
                  <Chip color={"primary"} label={v?.multiple} />
                )}
              </>
            }
          />
          </>

        ))}
      <FormControl>
        <Button
          onClick={() =>
            dispatch({type: 'increase-facets-limit', facetKey: key, limit: facetLimit ? 0 : 5})
          }
        >
          Ver {facetLimit ? "Mas" : "Menos"}
        </Button>
      </FormControl>
    </FormGroup>
  );
}

const DateFilter = ({ filter, dispatch, disabledDefaultFilters = false}) => {
  const startFilter = !disabledDefaultFilters && filter?.defaultFilter?.start;
  const endFilter = !disabledDefaultFilters && filter?.defaultFilter?.end;
  
  const from = !disabledDefaultFilters && startFilter
  ? moment().add(startFilter[0], startFilter[1])
  : null
  
  const to = !disabledDefaultFilters && endFilter
  ? moment().add(endFilter[0], endFilter[1])
  : null
  const [dates, setDates] = useState([from, to]);

  const onChangeDate = (date) => {
    setDates(date);
    const from = date?.[0]?.unix();
    const to = date?.[1]?.unix();
    
    dispatch({type: 'set-default-filters', keepPrevius: true, nextFilters: { [filter.field+ "_timestamp"] : { $gt: from, $lt: to }}})
    dispatch({type: 'set-match-field', field: filter.field, value: { $gt: from, $lt: to } });
    dispatch({type: 'set-sort-field', field: `${filter.field}_timestamp`, value: -1 });
  };
  return (
    <Box sx={{ marginY: 1 }}>
      <Typography>{filter?.name} </Typography>
        <MuiDateRangePicker
          name="allowedRange"
          calendars={2}
          localeText={{ start: "Desde", end: "Hasta" }}
          direction="row"
          onChange={onChangeDate}
          slotProps={{
            field: { clearable: true, onClear: () => {
              return dispatch({type: 'delete-default-filter-by-key', keyToRemove: filter.field+ "_timestamp"})
            } },
          }}
          date={dates}
        />
    </Box>
  );
};
