import { useLocation, useHistory } from "react-router-dom";
import QueryString from "qs";

export function useSearchParams() {
  const location = useLocation();
  const history = useHistory();
  let searchParams = new URLSearchParams(location.search);

  const parsedHash = QueryString.parse(
    history.location.search?.replace("?", ""),
  );
  const addParam = (param, value) => searchParams.append(param, value);

  const getParam = (param) => {
    const getParam = searchParams.get(param);
    return getParam;
  };
  const getAllParams = (param) => {
    const getAllParam = searchParams.getAll(param);
    return getAllParam;
  };
  
  const deleteParam = (param, value) => searchParams.delete(param, value);

  const setParam = (param, value) => searchParams.set(param, value);

  const pushParams = () =>
    history.replace(location.pathname + "?" + searchParams.toString());

  return {
    pushParams,
    addParam,
    deleteParam,
    setParam,
    getParam,
    getAllParams,
    parsedHash,
    searchParams,
  };
}
