/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import React, { useEffect, useMemo, useReducer, useState } from "react";
import {
  DateTimePicker as DateTimePicker2,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import { matchSorter } from "match-sorter";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/styles";
import { green } from "@material-ui/core/colors";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import fill from "lodash/fill";
import get from "lodash/get";

import Button from "@material-ui/core/Button";
import { TextField } from "@mui/material";
import { useRoleDataContext } from "../../../../context/RoleContext";
import AddNew from "./AddNew";
import CustomFieldRender from "../../../CustomField";
import { algoliaIndex } from "../../../../algolia";
import InputUpload from "../../../Drive/UploadInput";
import _ from "lodash";
// import { useMongoAggregate } from "../../../../utils/mongoAggregate/useMongoAggregate";
// import { useHistory } from "react-router-dom";
// import { useDebounce } from "../../../Utils/useDebounce";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "-webkit-fill-available",
    "& > div": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  text: {
    marginBottom: theme.spacing(1),
  },
  title: {
    textDecoration: "underline",
  },
  toggleContainer: {
    margin: theme.spacing(1),
  },
  fileInput: {
    display: "none",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  accordionSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function EditForm({
  fields,
  hit,
  update,
  orginalHit,
  error,
  setError,
}) {
  const classes = useStyles();

  // const values = nested ? hit[nested] || {} : hit;
  // const setValue = h=>console.log(h);
  //
  // useEffect(() => {
  //     setValue({n: hit})
  // }, [hit]);

  return (
    <form className={classes.form}>
      <Fields
        classes={classes}
        fields={fields}
        values={hit}
        error={error}
        setError={setError}
        update={update}
        setValue={(a) => a}
        orginalHit={orginalHit}
      />
    </form>
  );
}

function Fields({
  fields = [],
  values = {},
  update,
  error,
  setError,
  transformBody,
  classes,
  setValue,
  hit,
  orginalHit,
}) {
  // eslint-disable-next-line no-param-reassign
  if (!hit) hit = values;
  return (
    <>
      {fields
        .filter(
          (f) =>
            (!f.options ||
              f.options.edit === undefined ||
              (typeof f.options.edit === "string" &&
                CustomFieldRender(hit, f.options.edit)) ||
              f.options.edit === true) &&
            !f.options?.buttons,
        )
        .map((f) => (
          <Field
            key={f.field}
            orginalHit={orginalHit}
            f={f}
            hit={hit}
            classes={classes}
            update={update}
            error={error}
            setError={setError}
            transformBody={transformBody}
            values={values}
            setValue={setValue}
          />
        ))}
    </>
  );
}
export function FieldTextField({ f, values, update }) {
  // const { error, setError } = props;
  const v = _.get(values, f.field);
  const [value, setValue] = useState(v || "");
  // const router = useHistory();
  // const debounceAggregate = useDebounce(value, 300);
  // const index = router.location.pathname.split("/").pop();

  // const [data] = useMongoAggregate({
  //   index,
  //   enabled: f.unique === true && !!v?.length,
  //   aggregate: [{ $match: { [`data.${f.field}`]: debounceAggregate } }],
  // });
  // console.log("f", f);

  useEffect(() => {
    setValue(v);
  }, [v]);

  // useEffect(() => {
  //   if(typeof f === 'object') {
  //
  //     if (data?.length) {
  //       setError({ [f.field]: true });
  //     } else {
  //       setError({ [f.field]: false });
  //     }
  //   }
  // }, [data]);

  return (
    (f.options && f.options.type === "multiline" && (
      <TextField
        autocomplete="off"
        multiline
        label={f.name}
        type="text"
        value={value || ""}
        onChange={(e) => setValue(e.target.value)}
        onBlur={(e) =>
          update({
            field: f.field,
            value:
              f.options.type === "number"
                ? Number(e.target.value)
                : e.target.value,
          })
        }
        rowsMax="4"
        variant="outlined"
      />
    )) || (
      <TextField
        // error={typeof f === "object" ? error?.[f.field] === true : false}
        autocomplete="off"
        multiline={!(f.options && f.options.type !== "text")}
        label={f.name}
        type={(f.options && f.options.type) || "text"}
        value={value || ""}
        maxLength={f.options && f.options.maxLength}
        InputProps={{ maxLength: 2 }}
        onChange={(e) =>
          setValue(
            f.options && f.options.type === "number"
              ? f.options && f.options.maxLength
                ? Number(
                    String(e.target.value).substring(0, f.options.maxLength),
                  )
                : e.target.value
              : f.options && f.options.maxLength
              ? e.target.value
                  .replace("\n", "")
                  .substring(0, f.options.maxLength)
              : e.target.value.replace("\n", ""),
          )
        }
        onKeyPress={(e) => e.key === "Enter" && e.target.blur()}
        onBlur={(e) =>
          update({
            field: f.field,
            value:
              f.options && f.options.type === "number"
                ? Number(e.target.value)
                : e.target.value.trim(),
          })
        }
        rowsMax="4"
        variant="outlined"
      />
    )
  );
}

export function Field({
  f,
  values,
  update,
  transformBody,
  classes,
  setValue,
  error,
  setError,
  hit,
  orginalHit,
}) {
  const value = get(values, f.field);

  f.options?.type === "datetime" && console.log(value);

  return (
    (f.options &&
      (f.options.type === "autocomplete" ? (
        <AutocompleteWhithOptions
          field={f}
          values={value || false}
          onChange={(v) => update({ field: f.field, value: v })}
        />
      ) : f.options.multiple ? (
        <Multiple
          classes={classes}
          field={f}
          values={values}
          update={update}
          setValue={setValue}
          hit={hit}
          orginalHit={orginalHit}
        />
      ) : f.options.nested ? (
        <Nested
          classes={classes}
          field={f}
          values={values}
          update={update}
          setValue={setValue}
          hit={hit}
          orginalHit={orginalHit}
        />
      ) : f.options.index ? (
        <Asynchronous
          hit={values}
          filters={
            f.options.index.filters ||
            (f.options.index.customFilters &&
              CustomFieldRender(hit, f.options.index.customFilters)) ||
            ""
          }
          facetFilters={
            (f.options.index.facetFilters &&
              JSON.parse(
                CustomFieldRender(hit, f.options.index.facetFilters),
              )) ||
            []
          }
          transformBody={
            f.options.transformBody || (transformBody && transformBody[f.field])
          }
          index={f.options.index.name}
          field={f.options.index.field}
          customText={f.options.index.customText}
          multiple={f.options.index.multiple}
          title={f.name}
          values={value || false}
          onChange={(v) => update({ field: f.field, value: v })}
        />
      ) : (
        (f.options.type === "checkbox" && (
          <FormControlLabel
            control={
              <Checkbox
                checked={!!value}
                onChange={(event) =>
                  update({
                    field: f.field,
                    value: event.target.checked,
                  })
                }
                name={f.name}
              />
            }
            label={f.name}
            labelPlacement="start"
          />
        )) ||
        (f.options.type === "select" && !f.options.multiple && (
          <div className={classes.toggleContainer}>
            <ToggleButtonGroup
              color="primary"
              onChange={(_e, v) => update({ field: f.field, value: v })}
              exclusive
              value={value}
              aria-label="primary"
            >
              {Object.keys(f.options.options).map((o) => (
                <ToggleButton color="primary" key={o} value={o}>
                  {f.options.options[o]}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
        )) ||
        (f.options.type === "file" && (
          <InputUpload
            // eslint-disable-next-line no-underscore-dangle
            filename={
              values[`${f.field}_name`] ||
              (value && value._name) ||
              (typeof value === "string" ? value : "")
            }
            fileId={value}
            accept={f.options.file && f.options.file.accept}
            folderId={
              f.options.file && CustomFieldRender(hit, f.options.file.folderId)
            }
            onUploaded={(v, filename) =>
              update({
                hit: {
                  [f.field]: v,
                  [`${f.field}_name`]: filename,
                },
              })
            }
            title={f.name}
          />
        )) ||
        (f.options.type === "image" && (
          <InputUpload
            accept={"image/*"}
            uploadTo={f.options?.server}
            optimized={f.options?.optimized}
            fullPath={CustomFieldRender(hit, f.options?.fullPath)}
            preview={
              value && <img src={value} alt={value} style={{ width: "80px" }} />
            }
            onUploaded={(v) =>
              update({
                hit: {
                  [f.field]: v,
                },
              })
            }
            title={f.name}
          />
        )) ||
        (f.options.type === "date" && (
          <DatePicker
            value={value}
            label={f.name}
            onChange={(v) => update({ field: f.field, value: v })}
          />
        )) ||
        (f.options.type === "datetime" && (
          <DateTimePicker
            value={
              _.isDate(value)
                ? value.getTime()
                : _.isNumber(value)
                ? String(value || "").length > 11
                  ? value
                  : value * 1000
                : typeof value === "object"
                ? value.seconds * 1000
                : value
            }
            label={f.name}
            onChange={(v) =>
              update({
                field: f.field,
                value: (v?.toDate?.() / 1000) | 0,
              })
            }
          />
        ))
      ))) ||
    React.createElement(FieldTextField, {
      f,
      values,
      error,
      setError,
      update,
      transformBody,
      classes,
      setValue,
      hit,
      orginalHit,
    })
  );
}

function multipleHit(values, newValues, nested) {
  if (newValues) {
    const nhit = {};
    let number;
    // eslint-disable-next-line no-unused-expressions, prefer-destructuring
    for (const i in newValues) {
      /^[0-9]+$/.test(i.split(".")[0]) && (number = i.split(".")[0]);
    }
    nhit[nested] = typeof values[nested] === "object" ? values[nested] : [];
    // for (let j = 0; j <= number; j++) nhit[nested][j] === undefined && (nhit[nested][j] = {});
    if (!nhit[nested][number]) nhit[nested][number] = {};
    for (const i in newValues) {
      const field = i.replace(number, "").replace(/\./g, "");
      i === number ?  nhit[nested][number] = newValues[i] : (nhit[nested][number][field] = newValues[i]);
    }
    // for (let i in nhit[nested]) if(!nhit[nested][i]) nhit[nested].splice(i,1);
    return { hit: nhit };
  }
  return {};
}

const randomColor = () =>
  `hsl(${Math.floor(Math.random() * 360)}, 100%, ${
    Math.floor(Math.random() * 10) + 80
  }%)`;
function nestedHit(hit, nested) {
  const nhit = {};
  // eslint-disable-next-line guard-for-in
  for (const i in hit) nhit[`${nested}.${i}`] = hit[i];
  return hit && Object.keys(hit).length ? { hit: nhit } : {};
}
function Nested({
  field: nestedField,
  values,
  update,
  classes,
  setValue,
  hit,
  orginalHit,
}) {
  const color = useMemo(() => randomColor(), []);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        style={{ backgroundColor: color }}
      >
        <Typography className={classes.accordionHeading}>
          {nestedField.name}
        </Typography>
        <Typography className={classes.accordionSecondaryHeading}>
          Tocar para ampliar
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.form}>
          <Fields
            classes={classes}
            orginalHit={orginalHit[nestedField.field] || {}}
            setValue={({ f, v }) =>
              setValue({ f: `${nestedField.field}.${f}`, v })
            }
            nested={nestedField.field}
            fields={nestedField.options.nested}
            // eslint-disable-next-line no-shadow
            update={({ field, hit, ...r }) =>
              update({
                ...r,
                field: field && `${nestedField.field}.${field}`,
                ...nestedHit(hit, nestedField.field),
              })
            }
            values={values[nestedField.field] || {}}
            hit={hit}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

function Multiple({
  field: nestedField,
  values,
  update,
  classes,
  setValue,
  hit,
  orginalHit,
}) {
  const [count, setCount] = useState((values[nestedField.field] || []).length);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        style={{ backgroundColor: randomColor() }}
      >
        <Typography className={classes && classes.accordionHeading}>
          {nestedField.name}
        </Typography>
        <Typography className={classes && classes.accordionSecondaryHeading}>
          Tocar para ampliar
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: "block" }}>
        <div className={classes && classes.form}>
          <Fields
            classes={classes}
            orginalHit={orginalHit[nestedField.field] || {}}
            setValue={({ f, v }) =>
              setValue({ f: `${nestedField.field}.${f}`, v })
            }
            nested={nestedField.field}
            fields={fill(Array(count), 0).map((a, i) => ({
              ...nestedField,
              field: String(i),
              name: `${nestedField.name} ${i}`,
              options: { ...nestedField.options, multiple: false },
            }))}
            update={({ field, hit, value }) => {
              // eslint-disable-next-line max-len
              update(
                multipleHit(
                  orginalHit,
                  { ...hit, ...(field ? { [field]: value } : {}) },
                  nestedField.field,
                ),
              );
            }}
            values={values[nestedField.field] || []}
            hit={hit}
          />
        </div>
        <div>
          <Button onClick={() => setCount((i) => (i || 0) + 1)}> Mas 1 </Button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

function DatePicker({ label, value, onChange }) {
  return (
    <KeyboardDatePicker
      margin="normal"
      label={label || "Date picker dialog"}
      inputVariant="outlined"
      format="DD/MM/yyyy"
      value={
        _.isNumber(value)
          ? value * 1000
          : value
          ? `${value} GMT-1100`
          : undefined
      }
      invalidDateMessage="Formato de Fecha Inválido"
      onChange={(d) =>
        d &&
        !Number.isNaN(d) &&
        onChange &&
        onChange(moment(d).format("yyyy-MM-DD"))
      }
      InputAdornmentProps={{ position: "start" }}
      KeyboardButtonProps={{
        "aria-label": "change date",
      }}
    />
  );
}

function DateTimePicker({ label, value, onChange }) {
  return (
    <DateTimePicker2
      label={label}
      ampm={false}
      inputVariant="outlined"
      value={value}
      onChange={onChange}
    />
  );
}

export function AutocompleteWhithOptions({ field, values, onChange }) {
  let { options } = field.options;
  const [selected, setSelected] = useState(
    typeof values === "string"
      ? options[values] && {
          title: options[values],
          objectID: values,
        }
      : typeof values === "object" && values[0]
      ? values.map((i) => ({
          title: options[i],
          objectID: i,
        }))
      : typeof values === "object" && Object.keys(values)
      ? values.objectID
        ? values
        : Object.keys(values).map((h) => values[h])
      : field.options.multiple
      ? []
      : null,
  );
  const [inputValue, setInputValue] = React.useState("");

  if (!options[0]) {
    const newOptions = [];
    for (const i in options)
      newOptions.push({ title: options[i], objectID: i });
    options = newOptions;
  }

  return (
    <Autocomplete
      value={selected}
      multiple={!!field.options.multiple}
      disabled={!!field?.options.disabled_options?.includes(selected?.objectID)}
      getOptionDisabled={(option) =>
        field?.options.disabled_options?.includes(option?.objectID)
      }
      onChange={(event, newValue) => {
        if (onChange) {
          onChange(
            newValue && (newValue.objectID || newValue.map((v) => v.objectID)),
          );
        }
        setSelected(newValue);
      }}
      inputValue={inputValue}
      getOptionSelected={(option, value) => option.objectID === value.objectID}
      getOptionLabel={(option) =>
        option && option.title
          ? option.title
          : field.customField
          ? field.customField(option, field.customField)
          : option[field.options.field]
      }
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      // eslint-disable-next-line react/jsx-props-no-spreading
      renderInput={(params) => (
        <TextField
          {...params}
          label={field.name}
          variant="outlined"
          autocomplete="off"
        />
      )}
    />
  );
}

export function Asynchronous({
  index,
  field,
  transformBody,
  title,
  multiple,
  values,
  filters,
  onChange,
  customText,
  facetFilters,
  openOnFocus,
  disabled,
  customStyle,
  autoFocus,
}) {
  const [openDialogAddNew, setOpenDialogAddNew] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useReducer(
    (o, a) => {
      if (a === "reset") return { o: {}, h: [] };
      for (const i in a) o.o[a[i].objectID] = a[i];
      o.h = Object.keys(o.o).map((h) => o.o[h]);
      o.h = matchSorter(o.h, searchText, {
        keys: o.h[0] && Object.keys(o.h[0]),
      });
      if (multiple) {
        for (const i in selected) {
          if (!o.h.find((h) => h.objectID === selected[i].objectID)) {
            o.h.push(selected[i]);
            o.o[selected[i].objectID] = selected[i];
          }
        }
      } else if (
        selected &&
        selected.objectID &&
        !o.h.find((h) => h.objectID === selected.objectID)
      ) {
        o.h.push(selected);
        o.o[selected.objectID] = selected;
      }

      return { ...o };
    },
    { o: {}, h: [] },
  );
  const loading = open && options.h.length === 0;

  const roleData = useRoleDataContext();

  useEffect(() => {
    setOptions("reset");
  }, [filters]);

  useEffect(() => {
    if (values)
      return setSelected(
        typeof values === "object" && _.size(values)
          ? values.objectID
            ? multiple ? [values] : values
            : _.values(values)
          : multiple
          ? []
          : null,
      );
    setSelected([]);
  }, [values, multiple]);

  useEffect(() => {
    let active = true;

    // if (!loading) {
    //     return undefined;
    // }

    (async () => {
      await sleep(loading ? 0 : 200);
      if (active && (searchText || loading) && open) {
        const values = await algoliaIndex(index)
          .search(searchText, { filters, facetFilters })
          .then(({ hits }) => hits);
        if (active) setOptions(values);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, index, searchText, open, filters, facetFilters]);

  // React.useEffect(() => {
  //     if (!open) {
  //         setOptions([]);
  //     }
  // }, [open]);

  const filterOptions = (opt, { inputValue }) => {
    const h = Object.keys(options.o).map((h) => options.o[h]);
    sleep(100).then(() => setSearchText(inputValue));
    const filtered = matchSorter(h, inputValue, {
      keys: h[0] && Object.keys(h[0]),
    });
    if (roleData.new && roleData.new.includes(index) && inputValue !== "") {
      filtered.push({
        inputValue,
        title: `Agregar "${inputValue}"`,
        addingNew: true,
      });
    }
    return filtered;
  };

  return (
    <>
      {openDialogAddNew && (
        <AddNew
          index={index}
          hit={{
            // campos por default
            ...(filters
              ? (() => {
                  const hit = {};
                  const fs = filters.split(" AND ");
                  // eslint-disable-next-line prefer-destructuring
                  for (const i in fs)
                    if (!hit[fs[i].split(":")[0]])
                      hit[fs[i].split(":")[0]] = fs[i].split(":")[1];
                  return hit;
                })()
              : {}),
            ...(field ? { [field]: searchText } : {}),
          }}
          open={openDialogAddNew}
          setOpen={setOpenDialogAddNew}
          onAdd={(id, hit) => {
            hit.objectID = id;
            if (multiple) {
              if (onChange) onChange([...selected, hit].map((v) => v.objectID));
              setSelected([...selected, hit]);
            } else {
              if (onChange) {
                onChange(id, hit);
              }
              setSelected(hit);
            }
          }}
        />
      )}
      <Autocomplete
        disabled={!!disabled}
        style={{ ...(customStyle ? customStyle : {}) }}
        openOnFocus={openOnFocus || false}
        disablePortal={openOnFocus || false}
        popperDisablePortal={openOnFocus || false}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        multiple={!!multiple}
        getOptionSelected={(option, value) =>
          option.objectID === value.objectID
        }
        getOptionLabel={(option) =>
          option && option.title
            ? option.title
            : transformBody
            ? transformBody(option)
            : customText
            ? CustomFieldRender(option, customText)
            : option[field]
        }
        options={options.h}
        loading={loading}
        value={selected}
        filterOptions={filterOptions}
        onChange={(event, newValue) => {
          if (
            newValue &&
            (newValue.addingNew ||
              (newValue[0] && newValue.filter((v) => v.addingNew).length))
          ) {
            setOpenDialogAddNew(true);
          } else {
            if (onChange) {
              onChange(
                newValue &&
                  (newValue.objectID || newValue.map((v) => v.objectID)),
                newValue,
              );
            }
            if (!newValue) return;
            setSelected(newValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            autocomplete="off"
            autoFocus={!!autoFocus}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            label={title}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
}
