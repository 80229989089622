import DataGridPremium from "../../../../../components/DataGrid/DataGrid";
import { darken, lighten, styled } from "@mui/material/styles";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { Stack } from "@mui/material";
import useData from "./useData";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import Filters from "./Filters";
import { numberWithCommas } from "../../../../../components/Utils/NumberWithCommas";
import moment from "moment/moment";
import { useDebounce } from "../../../../../components/Utils/useDebounce";
import { tableRequestReducer } from "./table-reducer";

export default function Table({
  index = "transactions",
  columns = [],
  disabledDefaultFilters,
  limit = 50,
  defaultSort = {},
  defaultFilters = { "objectID" : { $exists: true }},
  height = 400,
  ...props
}) {
  let apiRef = useGridApiRef(null);

  useEffect(()=> {
    apiRef = {
      current: null
    }
  },[])
  const allFacets = columns.reduce(
    (p, a) => ({
      ...p,
      [_.snakeCase(a.field)]: {
        field: a.field,
        limit: a.options?.limit || 5,
        name: a?.headerName,
        multiple: a.options?.multiple,
        lookup: a?.options?.index,
        type: a?.options?.type,
        defaultFilter: a?.options?.defaultFilter,
      },
    }),
    {},
  );

  const initialTableStages = {
    sort: defaultSort,
    size: limit,
    match: {},
    limit,
    defaultFilters,
    facets: columns
      .filter((c) => c.options?.filter && c.options?.type !== "date")
      .reduce(
        (p, a) => ({
          ...p,
          [_.snakeCase(a.field)]: {
            field: a.field,
            limit: a.options?.limit || 5,
            name: a?.headerName,
            multiple: a.options?.multiple,
            lookup: a?.options?.index,
            defaultFilter: a?.options?.defaultFilter,
          },
        }),
        {},
      ),
  }
  const [    
    {
    skip,
    sort,
    size,
    resetData,
    match,
    isSearching,
    facets,
    quickFilter,
    defaultFilters: defaultFiltersState,
  }, tableStagesDataDispatcher] = useReducer(tableRequestReducer, initialTableStages, (p) => p)
  useEffect(() => {
    if(defaultFilters){
      !_.isEqual(defaultFiltersState, defaultFilters) && 
      tableStagesDataDispatcher({ type:'set-default-filters', nextFilters: defaultFilters });
    }
    
  }, [defaultFilters]);

  useEffect(() => {
    tableStagesDataDispatcher({ type: 'remove-empty-data' })
  },[defaultFilters])
  const quickFilterDebounce = useDebounce(quickFilter, 300);

  const defaultMemoDateFilter = useMemo(
    () =>
      columns
        .filter((d) => d.options?.defaultFilter && d.options?.type === "date")
        .map((d) => {
          const startFilter = d.options?.defaultFilter?.start;
          const endFilter = d.options?.defaultFilter?.end;

          const from = startFilter
            ? moment().add(startFilter[0], startFilter[1])
              ?.unix()
            : null;

          const to = endFilter
            ? moment().add(endFilter[0], endFilter[1])
              ?.unix()
            : null;
          return {
            [`${d.field}_timestamp`]: { $gt: from, $lt: to },
          };
        })
        ?.reduce((p, a) => ({ ...p, ...a }), {}),
    [],
  );


  const addTimetampToField = useCallback((columns) => columns.map((d) =>
    d?.options?.type === "date" ? `${d.field}_timestamp` : d.field,
  ), [columns])

  const createLookupFromColumns = useCallback((columns) => columns
    .filter((d) => d?.options?.index)
    .map((d) => ({
      localField: d.field,
      from: d.options.index.name,
      as: d.field,
      fields: d.options.index.fields || [d.options.index.field],
    }))
    , [columns]) 
    
  const checkIfFieldIsDate = (key) => allFacets[_.snakeCase(key)]?.type === 'date';

  const [data, loading, , count, responseFacets] = useData({
    index,
    match: _.mapKeys(match, (v, k) => checkIfFieldIsDate(k) ? `${k}_timestamp`: (facets[k]?.field || Object.keys(match))),
    project: addTimetampToField(columns),
    lookup: createLookupFromColumns(columns),
    sort,
    isSearching,
    resetData,
    facetCount: skip,
    skip,
    facets,
    size,
    limit,
    defaultFilters: {
      ...defaultFiltersState,
      ...(quickFilterDebounce && columns.some((d) => d.options?.quickFilter)
        ? {
            $or: columns
              .filter((d) => d.options?.quickFilter)
              .map((d) => ({
                $and: quickFilterDebounce.map((v) => ({
                  [`data.${d.field}`]: {
                    $regex: v,
                    $options: "i",
                  },
                })),
              })),
          }
        : {}),
    },
    defaultDateFilters: {
      ...(!Object.values(match).length && columns.some(
        (d) => d.options?.defaultFilter && d.options?.type === "date",
      )
        ? {
            ...defaultMemoDateFilter,
          }
        : 
        // Se fija si se aplicó un filtro de tipo 'date', entonces lo convierte en un obj => {timestamp_timestamp: {...}}
        Object.assign({}, ...columns
          .filter(e => e?.options?.type === 'date' && match[e.field])
          .map(e => 
            ({
              [`${e?.field}_timestamp`]: match[e.field]
            })
          ))
  )}
  });

  const handleOnRowsScrollEnd = () => {
    if (!loading && count > size) {
      tableStagesDataDispatcher({type: 'reset-data', value: false})
      tableStagesDataDispatcher({
        type: 'increment-data-limit', 
        value: size + limit
      })
    }
    return;
  };
  const handleSortModelChange = (sortModel) => {
    if (!loading && sortModel?.length) {
      return tableStagesDataDispatcher({
        type: 'handle-sort-model', 
        sortModel,
        defaultSort,
        fieldType: Object.values(allFacets).find(facet => facet.field === sortModel[0]?.field)?.type
       })
    }
  };
  
  const handleFilterModelChange = (r) => {

    //Getting all the columns that have quickFilter on true
    const columnsWithQuickFilter = columns
    .filter(column => column?.options?.quickFilter)
    .map(col => col?.field)
   
    //Making a obj from those columns
    const quickFilterObj = {"$or":Object.entries(columnsWithQuickFilter.reduce((o, key) => ({ ...o, [key]: {
      "$regex": r.quickFilterValues.join(' '),
        "$options": "i"
    }}), {})).map(e => ({[`data.${e[0]}`]: e[1]}))
    }

    tableStagesDataDispatcher({
      type: 'set-default-filters',
      keepPrevius: true, 
      nextFilters: quickFilterObj,
    })
    tableStagesDataDispatcher({type: 'reset-data', value: true})
  };
  const gridColumns = useMemo(() => columnsFix(columns), [columns]);
  useEffect(() => {
      if(apiRef && apiRef?.current){
        setTimeout(() => {
          Object.hasOwn(apiRef?.current || {}, 'scrollToIndexes') && 
            apiRef?.current?.scrollToIndexes?.({ rowIndex: 0, colIndex: 0 })
        }, 500)
      }
  }, [defaultFiltersState, apiRef]);
  return (
    
    <Stack direction="row" gap={4} wrap="nowrap" sx={{paddingRight: 4}}>
      <Stack maxHeight={height} overflow="scroll" minWidth="300px">
          <Filters
            columns={columns}
            match={match}
            disabledDefaultFilters={disabledDefaultFilters}
            defaultFilters={defaultFilters}
            dispatch={tableStagesDataDispatcher}
            dispatchFacets={facets}
            responseFacets={responseFacets}
            requestFacets={allFacets}
            facets={responseFacets}
        />
        </Stack>
    <div style={{ height: "800px", minWidth:0, flexGrow: 1, backgroundColor: 'white', padding: 6, borderRadius: 4 }}>
        <StyledDataGrid
          fileName={`${index} - ${moment().format("DD/MM/YYYY")}`}
          apiRef={apiRef}
          columns={gridColumns}
          rows={data.filter(e => e)}
          getRowId={() => Math.floor(Math.random() * 100000000) }
          rowCount={count}
          processRowUpdate={(updatedRow, oldRow) =>
            console.log({ updatedRow, oldRow }) && updatedRow
          }
          hideFooterPagination
          onRowsScrollEnd={handleOnRowsScrollEnd}
          sortingMode="server"
          filterMode="server"
          onSortModelChange={handleSortModelChange}
          onFilterModelChange={handleFilterModelChange}
          loading={limit > count ? false : loading}
          {...props}
      />
  </div>
    </Stack>
  );
}

const columnsFix = (columns) => {
  return [
    ...columns
      ?.filter((c) => !c?.options?.hide)
      ?.map((c) => {
        let cc = { ...c };
        c.field?.includes(".") &&
          (cc.valueGetter = ({ row }) => _.get(row, c.field));
        c.options?.index &&
          (cc.valueGetter = ({ value }) =>
            value?.map((v) => _.get(v, c.options.index.field, "")).join("; "));
        c.options?.type === "date" &&
          (cc.renderCell = (data) => {
            const value = data?.row?.[`${data?.field}_timestamp`];
            return moment(value * 1000)?.isValid()
              ? moment(value * 1000).format("DD/MM HH:mm")
              : "-";
          });
        c.options?.type === "currency" &&
          (cc.renderCell = ({ value }) =>
            `$${numberWithCommas(value, c.options.decimals || 0)}`);
        return cc;
      })
  ];
};

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const statesColors = {
  asset: "info",
  assets_outstanding: "info",
  issued_pending: "info",
  deposited: "secondary",
  in: "success",
  custody: "warning",
  out: "error",
  bounced: "error",
};

const StyledDataGrid = styled(DataGridPremium)(({ theme }) =>
  _.mapValues(
    _.mapKeys(statesColors, (v, k) => `& .super-app-theme--${k}`),
    (v) => ({
      backgroundColor: getBackgroundColor(
        theme.palette[v].main,
        theme.palette.mode,
      ),
      "&:hover": {
        backgroundColor: getHoverBackgroundColor(
          theme.palette[v].main,
          theme.palette.mode,
        ),
      },
      "&.Mui-selected": {
        backgroundColor: getSelectedBackgroundColor(
          theme.palette[v].main,
          theme.palette.mode,
        ),
        "&:hover": {
          backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette[v].main,
            theme.palette.mode,
          ),
        },
      },
    }),
  ),
);
