/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
import { useEffect, useState } from "react";

export default function useScannedCode(active = true) {
  let [{ scannedCode, timestamp }, setScannedCode] = useState({
    scannedCode: "",
    timestamp: (new Date() / 1000) | 0,
  });

  useEffect(() => {
    if (!active) return () => false;

    let effectactive = true;
    let pressedKeys = "";
    const listenKeyPress = (e) => {
      e.preventDefault();
      return e.key === "Enter"
        ? [
            effectactive &&
              setScannedCode({
                scannedCode: pressedKeys,
                timestamp: (new Date() / 1000) | 0,
              }),
            (pressedKeys = ""),
          ]
        : (pressedKeys += e.key);
    };
    document.addEventListener("keypress", listenKeyPress);
    return () => {
      effectactive = false;
      document.removeEventListener("keypress", listenKeyPress);
    };
  }, [active]);

  return { scannedCode, timestamp, setScannedCode };
}
