import moment from "moment";
import { useMongoAggregate } from "../../../utils/mongoAggregate/useMongoAggregate";

export const useGetRoadmapsToLoad = () => {
  const [data, isLoading] = useMongoAggregate({
    index: "roadmaps",
    aggregate: [
      {
        $match: {
          "data.status": { $ne: "pending" },
          "data.date_timestamp": {
            $gt: moment().subtract(1, "days").unix(),
            // $lt: moment().add(1, "days").unix(),
          },
        },
      },
      {
        $project: {
          _id: 0,
          driver: "$data.data.driver",
          shipping: "$data.data.shipping",
          vehicleType: "$data.data.vehicleType",
          date: "$data.date",
          date_timestamp: "$data.date_timestamp",
          itemsQ: {
            $size: { $objectToArray: "$data.items" },
          },
          objectID: "$data.objectID"
        },
      },
      {
        $sort: {
          date_timestamp: 1,
          shipping: -1,
        }
      }
    ],
  });

  return [data, isLoading];
};
